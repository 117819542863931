export default ()=>({
    surveys:{},
    forms:[],
    surveysList: [],
    totalSurveys:0,
    surveyToFill:{},
    user:null,
    images:[],
    appVersion:2.3,
    dashBoardData:[],
    filters: [],
    searchStore: "",
    selectExtStore: "",
    selectSurveyStore: "",
    selectMunStore: "",
    selectDepStore: "",
    selectProjectStore: "",
})